<template>
    <TopNavigation step="0" title="Document Preview" icon="searchengin" hideSecondNavbar="true" />

    <div class="contentDiv">
      <div class="row">
        <!-- <span class="toolboxBtn" @click="toggleToolbox">
          <fa :icon="toolboxBtnIcon" class="text-white" />
        </span> -->

        <span v-if="!showToolbox" class="toolboxBtnClose" @click="toggleToolbox">
          <fa icon="chevron-right" class="text-white" />
        </span>

        <span v-if="showToolbox" class="toolboxBtnOpen" @click="toggleToolbox">
          <fa icon="chevron-left" class="text-white" style="padding-left: 2px;" />
        </span>
        
        <!-- Toolbox -->
        <!-- <div v-if="showToolbox" class="bottom-0 p-0 vh-100 pe-1" :class="isToolbarFloat ? 'toolbarFloat' : 'col-5 col-md-3 col-lg-2'" style="z-index: 2"> -->
        <div v-if="showToolbox" class="bottom-0 p-0 vh-100 pe-1 toolbarFloat" style="z-index: 2">
            <div id="accordion" class="pt-1 ps-1">
                <div class="card mb-2">

                    <div class="card-header pe-1 pt-1 text-center">
                        <table align="center">
                          <tr>
                            <td class="pe-1">
                                <button class="btn btn-secondary" @click="router.push('/project')">
                                  <Popper class="popperDark" arrow hover content="Folder Rack">
                                    <span><fa icon="folder" class="me-1" />Rack</span>
                                  </Popper>  
                                </button>
                            </td>
                            <td class="float-end">
                                <button class="btn btn-secondary" @click="router.push('/project/' + route.params.projectId)">
                                  <Popper class="popperDark" arrow hover content="Folder Overview">
                                    <span><fa icon="chart-line" class="me-1" v-cloak/>Overview</span>
                                  </Popper>
                                </button>
                            </td>
                          </tr>
                        </table>

                    </div>

                    <div id="left1" class="collapse show" >
                        <div class="card-body">
                            
                            <div class="pb-2 border-bottom">
                                <table align="center" border="0">
                                    <tr> 
                                    <td  width="50px" class="px-2 text-end isLink" @click="zoomOut"><fa icon="minus"></fa></td>
                                    <td v-if="scale === 1"  width="70px" class="pe-2 text-center" @click="zoomDefault">{{Math.floor(scale*100)}}%</td>
                                    <td v-else  width="70px" class="pe-2 isLink text-center" @click="zoomDefault">{{Math.floor(scale*100)}}%</td>
                                    <td  width="50px" class="ps-2 isLink pe-1" @click="zoomIn"><fa icon="plus"></fa></td>
                                    </tr>
                                </table>
                            </div>
                            <!-- Big screen -->
                            <div class="py-2 d-none d-md-block">
                                <table border="0" align="center">
                                    <tr>
                                    <td width="20px">&nbsp; </td>
                                    <td v-if="page === 1" class="pe-2 text-secondary"><fa icon="angle-double-left" :disabled="page === 1"></fa></td>
                                    <td v-if="page !== 1" class="pe-2 isLink" @click="navigatePage('first'); updPageNum(page)" ><fa icon="angle-double-left"></fa></td>
                                    <td v-if="page <= 1" class="px-2 text-secondary"><fa icon="angle-left"></fa></td>
                                    <td v-if="page > 1" class="px-2 isLink" @click="navigatePage('prev'); updPageNum(page)"><fa icon="angle-left"></fa></td>
                                    <td><input type="text" v-model="goToPage" class="form-control form-control-sm" @input="navigatePage(); updPageNum(page)" style="width: 40px" ></td>
                                    <td class="px-2">/ {{ pageCount }}</td>
                                    <td v-if="page >= pageCount" class="px-2 text-secondary"><fa icon="angle-right"></fa></td>
                                    <td v-if="page < pageCount" class="px-2 isLink" @click="navigatePage('next'); updPageNum(page)"><fa icon="angle-right"></fa></td>
                                    <td v-if="page === pageCount" class="ps-2 text-secondary"><fa icon="angle-double-right" ></fa></td>
                                    <td v-if="page !== pageCount" class="ps-2 isLink" @click="navigatePage('last'); updPageNum(page)"><fa icon="angle-double-right"></fa></td>
                                    </tr>
                                </table>
                            </div>
                            <!-- Big screen End-->
                            <!-- Small screen -->
                            <div class="d-block d-md-none">
                                <div class="row mb-2 mt-2 pb-2 border-bottom" style="margin-left: 1px; margin-right: 1px;">
                                    <div v-if="page === 1" class="col-3 text-secondary">
                                        <fa icon="angle-double-left" :disabled="page === 1"/>
                                    </div>
                                    <div v-if="page !== 1" class="col-3 isLink" @click="navigatePage('first'); updPageNum(page)">
                                        <fa icon="angle-double-left" />
                                    </div>

                                    <div v-if="page <= 1" class="col-3 text-secondary">
                                        <fa icon="angle-left" />
                                    </div>
                                    <div v-if="page > 1" class="col-3 isLink" @click="navigatePage('prev'); updPageNum(page)">
                                        <fa icon="angle-left"/>
                                    </div>

                                    <div v-if="page >= pageCount" class="col-3 text-secondary">
                                        <fa icon="angle-right" />
                                    </div>
                                    <div v-if="page < pageCount" class="col-3 isLink" @click="navigatePage('next'); updPageNum(page)">
                                        <fa icon="angle-right" />
                                    </div>

                                    <div v-if="page === pageCount" class="col-3 text-secondary">
                                        <fa icon="angle-double-right" />
                                    </div>
                                    <div v-if="page !== pageCount" class="col-3 isLink" @click="navigatePage('last'); updPageNum(page)">
                                        <fa icon="angle-double-right" />
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-6 text-end"><input type="text" v-model="goToPage" class="form-control form-control-sm float-end" @input="navigatePage(); updPageNum(page)" style="width: 40px" ></div>
                                    <div class="col-6 pt-2 ps-0">/ {{ pageCount }}</div>
                                </div>
                            </div>
                            <!-- Small screen End -->
                        </div>
                    </div>
                </div>

                <div v-if="doc" class="card mb-2">
                    <div class="card-header">
                        <a class="collapsed btn" data-bs-toggle="collapse" href="#left3">Document Information</a>
                        <span v-if="docStatus !== ''"><DocStatus :status="docStatus" class="me-2" /></span>
                    </div>
                    <div id="left3" class="collapse show" >
                        <div class="card-body pb-0">
                            <!-- Method-2 -->
                            <div class="row">
                                <div class="col-12 pb-0 mb-0">{{ (docName.length > 28 ? docName.substring(0, 28) + '...' : docName) }}</div>
src/views/pdf/ViewBackup.vue
                                <div v-if="doc.dateModify" class="col-12 pb-2 text-end">
                                  <span class="lblTiny mt-0">Modified </span>
                                  <Popper class="popperDark" arrow hover :content="'Created ' + func.getDateTimeDiff(func.convDateTimeFormat(doc.dateCreate), new Date())">
                                      <span class="isTooltipsLabel lblTiny mt-0">{{ func.getDateTimeDiff(func.convDateTimeFormat(doc.dateModify), new Date()) }}</span>
                                  </Popper>
                                </div>

                                <div class="col-12 mb-0 lblTiny">Signee(s)</div>

                                <div v-if="docSignee && docSignee.length === 0" class="col-12 pb-2">N/A</div>

                                <div v-if="docSignee && docSignee.length > 0" class="col-12 px-1 pb-2">
                                    <div v-for="(s, sIdx) in docSignee" :key="s">
                                        <table v-if="sIdx < 5" border="0" width="100%">
                                          <tr>
                                              <td rowspan="2" width="38px">
                                                <Avatar :isUser="s.isUser" :avatar="s.url" :isVerified="s.isVerified" displayName="" width="35" height="35" class="ps-2"/>
                                              </td>
                                              <td style="font-size: 14px;">{{s.name}}</td>
                                          </tr>
                                          <tr>
                                              <td v-if="sIdx === 0" class="text-end align-bottom lblTiny">Creator</td>
                                              <td v-else class="text-end align-bottom lblTiny">{{s.role}}</td>
                                          </tr>
                                        </table>
                                    </div>

                                    <div v-if="showMoreSignee" v-for="(s1, sIdx1) in docSignee" :key="s1">
                                        <table v-if="sIdx1 >= 5" border="0" width="100%">
                                          <tr>
                                              <td rowspan="2" width="38px">
                                                <Avatar :isUser="s1.isUser" :avatar="s1.url" :isVerified="s1.isVerified" displayName="" width="35" height="35" class="ps-2"/>
                                              </td>
                                              <td style="font-size: 14px;">{{s1.name}}</td>
                                          </tr>
                                          <tr>
                                              <td class="text-end align-bottom lblTiny">{{s1.role}}</td>
                                          </tr>
                                        </table>
                                    </div>

                                    <div v-if="docSignee.length > 5 && !showMoreSignee" class="text-end isLink" @click="showMoreSignee = !showMoreSignee">+ {{docSignee.length - 5}} more</div>
                                    <div v-if="docSignee.length > 5 && showMoreSignee" class="text-end isLink" @click="showMoreSignee = !showMoreSignee">- {{docSignee.length - 5}} less</div>
                                </div>
                                
                                <div v-if="doc.remark" class="col-12 mb-0 lblTiny">
                                  <Popper class="popperDark" arrow hover content="Appear to all signees within the same doc" placement="top">
                                    Public Remark
                                  </Popper>
                                </div>
                                <div v-if="doc.remark" class="col-12 pb-2">{{doc.remark}}</div>

                                <div v-if="doc.note" class="col-12 mb-0 lblTiny">
                                  <Popper class="popperDark" arrow hover content="Appear to myself" placement="top">
                                    Private Note
                                  </Popper>
                                </div>
                                <div v-if="doc.note" class="col-12 pb-2 ">{{doc.note}}</div>
                            </div>

                        </div>
                    </div>
                </div>

            </div> 

        </div><!-- End of Toolbox -->
        
        <div :class="contentClass">
          <div class="vh-100 gx-0">
            <!-- PDF  -->
            <div v-if="!showUIBox" class="p-2">
              <div class="spinner-border spinner-border-sm"></div> Loading...
            </div>

            <!-- <div style="position: relative; width: auto; height: 100%; overflow: auto; top: 0px;"> -->
            <div style="position: relative; width: auto; height: 100%; overflow: auto; top: 0px;" class="pdfBg">

              <div class="parent" id="pdfContainer" ref="pdfContainer">
                  <vue-pdf-embed v-if="showUIBox" 
                    id="pdfRef"
                    ref="pdfRef"
                    :source="url"
                    :page="page"
                    @password-requested="handlePasswordRequest"
                    @rendered="handleDocumentRender"
                  />
              </div>
            </div>
            <!-- End of PDF -->
            <div class="fs-6">
                WIDTH | window: {{winWidth}} | viewer: <span class="text-primary">{{viewWidth}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>  

</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import VuePdfEmbed from 'vue-pdf-embed'
import { ref, inject, onMounted } from 'vue'
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import { useStore } from '@/stores/store'
import { useRoute, useRouter } from 'vue-router'
import DocStatus from '@/components/DocStatus.vue'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import Avatar from '@/components/Avatar.vue'

export default {
  name: 'PdfView',
  components: { TopNavigation, VuePdfEmbed, degrees, PDFDocument, rgb, StandardFonts, DocStatus, Popper, Avatar },
  setup () {
      const url = ref(null)
      
      const axios = inject('axios')
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const { getTotalPage } = useStore()
      const func = funcs

      const pdfContainer = ref(null)
      const pdfWidth = ref(0)
      const uiRatio = ref(0) // ratio between UI width and PDF actual width. 
      
      const doc = ref(null)
      const docName = ref(null)
      const docStatus = ref(null)
      const docSignee = ref([])
      const projName = ref(null)
      const showUIBox = ref(false)
      const showToolbox = ref(true)
      const showMoreSignee = ref(false)
      const contentClass = ref('col-12 p-0')
      const toolboxBtnIcon = ref('chevron-left')
      // const isToolbarFloat = ref(true)
      const curPage = ref(1) // update tally to page

      // const winWidth = ref(window.innerWidth)
      // const onLeftDrawer = ref(props.showToolbox)
      // console.info('onLeftDrawer def', onLeftDrawer.value)
      // console.info('projectId', store.getProjectId.value)

      const updPageNum = (page1) => {
        curPage.value = page1
      }

      const toggleToolbox = async () => {

        localStorage.setItem('hasClickViewToolbox', true)

        showToolbox.value = !showToolbox.value
        console.info('toggleToolbox', showToolbox.value)
        localStorage.setItem('leftViewDrawer', showToolbox.value)
      
        // location.reload() // Need to detect window size

      }

      const getDocument = async (id) => {
        axios.get( '/signon/' + route.params.projectId + '/' + id).then((res) => {
          // console.info('getDocument signees | RES', JSON.stringify(res.data.data.signees))
          
          if (res.data.status === 1) {
            doc.value = res.data.data
            url.value = res.data.data.file.viewUrl
            docName.value = res.data.data.file.name.replace('.pdf', '')
            docStatus.value = res.data.data.status

            pdfWidth.value = res.data.data.file.pageWidth

            if (uiRatio.value === 0) {
              // Cater for cannot capture uiRatio in handleDocument function
              uiRatio.value = pdfContainer.value.clientWidth/pdfWidth.value
              console.info('---- SET uiRatio (Composition API)', uiRatio.value, pdfContainer.value.clientWidth,' / ', pdfWidth.value)
          }


            // console.info('url', url.value)

            if (doc.value.signees) {
              // Get creator first
              doc.value.signees.forEach((item, idx) => {
                  if (item.name === doc.value.creator.displayName ) {
                      docSignee.value.push(item)
                      console.info('isV', JSON.stringify(item), item.isVerified)
                  }
              })
            }

            if (doc.value.signees) {
              // Get non-creator
              doc.value.signees.forEach((item, idx) => {
                  if (item.name !== doc.value.creator.displayName ) {
                      docSignee.value.push(item)
                      console.info('isV2', JSON.stringify(item), item.isVerified)
                  }
              })
            }

            setTimeout(() => showUIBox.value = true, 1000)
          } 
        })
        .catch((error) => {
          console.log('getDocument | FAILURE', error[0] + '. ' + error[1] + ': ' + error[2])
        })
      }

      const getProject = () => {
        console.info('---- func: getProject')
        return axios.get( '/signon/' + route.params.projectId).then((res) => {
          if (res.data.status === 1) {
              projName.value = res.data.data.title
          } 
        })
        .catch((error) => {
          console.log('getProj | FAILURE...', JSON.stringify(error))
        })
      }

      onMounted(() => {

        // console.info('********* onmounted', localStorage.getItem('hasClickViewToolbox'), typeof localStorage.getItem('leftViewDrawer'), localStorage.getItem('leftViewDrawer'))
        // First time login
        if (route.query.fl === true || route.query.fl === 'true') {
          alert.value.push({
            class: "success",
            title: "SUCCESS",
            message: "Welcome to SignOn."
          })
        }

        // Not first time login
        if (route.query.fl === false || route.query.fl === 'false') {
          if (route.query.l) { 
            alert.value.push({
              class: "success",
              title: "SUCCESS",
              message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
            })
          }
        }

        if (localStorage.getItem('leftViewDrawer') === 'true') {
          showToolbox.value = true

        } else if (localStorage.getItem('leftViewDrawer') === 'false') { 
            showToolbox.value = false

        } else {
          showToolbox.value = true
          localStorage.setItem('leftViewDrawer', true)
        }

        getDocument(route.params.documentId)
        getProject()

      })

      return { 
        store, route, router, func, showUIBox, url, contentClass, showToolbox, toolboxBtnIcon, curPage, getTotalPage, 
        doc, docName, docStatus, updPageNum, toggleToolbox, getDocument, 
        projName, showMoreSignee, docSignee, 
        pdfContainer, uiRatio, pdfWidth
      }
  },
  data () {
    return {
      isLoading: false,
      page: 1,
      pageCount: 1,
      goToPage: 1,
      showAllPages: false,
      scale: 0.8,
      transX: 0,
      transY: 0,
      winWidth: 0,
      viewWidth: 0, 
      getWindowWidth: 0, 
      getScreenWidth: 0
    }
  },
  watch: {
      showAllPages() {
        this.page = this.showAllPages ? null : 1
        localStorage.setItem('page', this.page)
        
      },
      showWidth() {
        console.info('showWidth', this.$refs.pdfContainer.clientWidth)
        return this.$refs.pdfContainer.clientWidth
      }
  },
  mounted () {

    // Initiate tooltip
    Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))
    Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))

    // console.info('mounted-- leftDrawer', '=' + this.leftDrawer)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()

    this.zoomDefault()
  },
  methods: {
    handleResize() {
      this.winWidth = window.innerWidth
      if (this.$refs.pdfContainer !== null) {
        this.viewWidth = this.$refs.pdfContainer.clientWidth // mounted only can use
      }
      // console.info('win', window.innerWidth + ', ' + window.innerHeight)
      // console.info('viewer', this.$refs.pdfContainer.clientWidth + ', ' + this.$refs.pdfContainer.clientHeight)
    },
    handleDocumentRender() {
      // console.info('a pageCount', this.$refs.pdfRef.pageCount)
      this.isLoading = false
      this.pageCount = this.$refs.pdfRef.pageCount

      localStorage.setItem('page', this.page)
      localStorage.setItem('totalPage', this.pageCount)
    },
    handlePasswordRequest(callback, retry) {
      callback(prompt(retry
        ? 'Enter password again'
        : 'Enter password'
      ))
    },
    navigatePage (opt) {
      if (opt === 'prev')
      {
          this.page--
          this.goToPage = this.page
      } else if (opt === 'next') {
          this.page++
          this.goToPage = this.page
      } else if (opt === 'first') {
          this.page = 1
          this.goToPage = this.page
      } else if (opt === 'last') {
          this.page = this.pageCount
          this.goToPage = this.page            
      } else {
          this.goToPage = this.goToPage * 1
          if (this.goToPage > 0 && this.goToPage <= this.pageCount) {
              this.page = this.goToPage
          } else { 
              this.page = 1
              this.goToPage = this.page
          }
      }

      localStorage.setItem('page', this.page)
    },

    zoomIn () {
      this.scale = this.scale + 0.1
      /* this.transX = this.transX + 44 - (this.scale * 4)
      this.transY = this.transY + 53 - (this.scale * 3) */ 

      // console.info('++++ zoom in', this.scale.toFixed(1))
      document.getElementById('pdfContainer').style.transform = 'scale(' + this.scale + ', ' + this.scale + ') translate(' + this.transX + 'px, ' + this.transY + 'px)'
      document.getElementById('pdfContainer').style.transformOrigin = 'right top'
      // console.info('win', window.innerWidth + ' ' + window.innerHeight)
    },
    zoomOut () {
      this.scale = this.scale - 0.1
      /* this.transX = this.transX - 40
      this.transY = this.transY - 50 */

      console.info('---- zoom out', this.scale)

      document.getElementById('pdfContainer').style.transform = 'scale(' + this.scale + ', ' + this.scale + ') translate(' + this.transX + 'px, ' + this.transY + 'px) '
      document.getElementById('pdfContainer').style.transformOrigin = 'right top'
    }, 
    zoomDefault () {
      console.info('---- default')
        this.scale = 0.8
        this.transX = 0
        this.transY = 0
        document.getElementById('pdfContainer').style.transform = 'scale(' + this.scale + ', ' + this.scale + ') translate(' + this.transX + 'px, ' + this.transY + 'px)'
        document.getElementById('pdfContainer').style.transformOrigin = 'right top'
    }
  }
}
</script>
/* PENDING:
- select doc affect remark project name
- show selected color by default
*/

// NOTE: fontFamily,fontSize pass from parent to DragBox component thr props, other from component to parent thr getPosition
// npm i vue3-draggable-resizable
// https://www.npmjs.com/package/vue3-draggable-resizable
// npm i vue-pdf-embed
// https://www.npmjs.com/package/vue-pdf-embed
// npm i pdf-lib
// https://www.npmjs.com/package/pdf-lib
// npm i downloadjs

// test https://www.npmjs.com/package/vue3-date-time-picker
// https://vue3datepicker.com/installation/#global
// npm i vue3-date-time-picker

// References
// https://www.designcise.com/web/tutorial/how-to-apply-css-opacity-to-background-color-only
// scan signature https://www.youtube.com/watch?v=0ArXBk6vS5U

<style>
body {
  color: #6E777E;
  font-size: 14px;
  overflow: hidden;
}

.pdfBg {
  background-image: url("../../assets/bgTrans1.png");
  width: 100%;
}

.parent {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px; 
  user-select: none;
  /* overflow-x: none; */
}
.box {
  /* border: 1px dashed #e98f07; */
  border-style: dashed;
  border-width: 3px;
  cursor: pointer;
  border-radius: 5px;
}
.box:hover {
  /* border: 2px dashed #e98f07; */
  border-style: dashed;
  border-width: 3px;
  cursor: move;
}
.box:active {
  cursor: move;
  /* border: 2px dashed #e98f07; */
  border-style: dashed;
  border-width: 3px;
}

.boxSelected {
  border-width: 4px;
  border-style: solid;
}

.boxCal {
  padding-left: 8px;
  padding-right: 8px;
  width: 200px;
}

.toolboxBtnClose {
  position: fixed; 
  margin-top: 6px;
  width: 20px; 
  height: 45px; 
  padding: 11px 2px; 
  background-color: grey;
  border-top-right-radius: 8px; 
  border-bottom-right-radius: 8px; 
  z-index: 9; 
  opacity: 0.8; 
  cursor: pointer
}

.toolboxBtnOpen {
  position: fixed;
  margin-top: 6px;
  left: 251px; 
  width: 20px; 
  height: 45px; 
  padding: 11px 2px; 
  background-color: grey;
  border-top-right-radius: 8px; 
  border-bottom-right-radius: 8px; 
  z-index: 9; 
  opacity: 0.8; 
  cursor: pointer
}

/* .toolboxBtn {
  position: fixed; 
  width: 20px; 
  height: 45px; 
  padding: 10px 2px; 
  background-color: grey;
  border-top-right-radius: 8px; 
  border-bottom-right-radius: 8px; 
  z-index: 9; 
  opacity: 0.8; 
  cursor: pointer
} */

.handle {
  cursor: move;
}

.card {
    background-color: var(--toolbar-bg);
    border: 1px solid var(--toolbar-border);
}

.card-header {
    padding: 0px;
    border-bottom: 0px;
    background-color: var(--toolbar-bg);
}

.card-header .btn {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
}

.card-header .btn:focus {
    box-shadow: 0 0 0 0;
}

.card-body {
    padding: 5px 12px;
}

.border-bottom {
  border-bottom: 1px solid var(--toolbar-hr) !important;
}

.toolbarFloat {
  position: fixed; 
  z-index: 2; 
  top: 41px;
  width: 255px; 
  opacity: 0.8; 
}
</style>